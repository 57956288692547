import React from 'react';
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <footer className='call'>
        <a href="tel:07897718109" className='link'>Call now on 07897718109</a>
    </footer>

    <footer>
      <p>Designed by DanielTheCode</p>
      <p>© Copyright 2022. All rights reserved.</p>
    </footer>
    </>
    
  )
}

export default Footer